import React from 'react';
import historyStyles from '../assets/scss/history.module.scss';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import HistoryData from './history-data';


const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    
  }));

export default function CancelModal({ title, open, setOpen }) {

  const  classes= useStyles();
    const handleClose = () => {
        setOpen(false);
    };


    const body = (
        <div className="modal" >
            <h2 className={['body-02', historyStyles.modalTitle].join(" ")} id="simple-modal-title">Are you sure you want to cancel your reservation?</h2>
            <p className={['text-02', historyStyles.modalPara].join(" ")} id="simple-modal-description">
                You’re about to cancel your reservation to “{title}”.
          </p>
            <p className={['text-02', historyStyles.modalPara].join(" ")} id="simple-modal-description">

                There’s a 50 EGP cancellation fee. Do you still wish to cancel?
          </p>
          <div className={historyStyles.cancelBtns}>
            <Button className={['button', historyStyles.modalgreybtn].join(" ")} onClick={handleClose}>Don't cancel</Button>
            <Button className={['button', historyStyles.modalorangebtn].join(" ")} href="#">Cancel my reservation</Button>
            </div>
        </div>
    );

    return (

        <Modal className={classes.modal}
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            {body}
        </Modal>
    );
}


