import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import History from"../components/history-component"
import { Router } from "@reach/router";
import PrivateRoute from "../components/privateRoute"

const HistoryPage = () => (
    <Layout pageName="History" noMargin>
      <Seo title="History" />
      <Router>
        <PrivateRoute path="/history" component={History}/>
      </Router>
    </Layout>
  )
  
  export default HistoryPage