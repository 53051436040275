
import React from 'react';
import historyStyles from '../assets/scss/history.module.scss';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CancelModal from './cancelation-modal';
// import handleOpen from './cancelation-modal';



export default function HistoryData({ title, user, date, time, fees, isUpcoming, isCanceled }) {

    

    const [open, setOpen] = React.useState(false);
  
    const handleOpen = () => {
        console.log('cancel clicked')
      setOpen(true);
    };


    return (
        

            <div className={historyStyles.dataContainer}>
                <h2 className={["h3", historyStyles.historyTitle].join(" ")}>{title}</h2>
                <p className={["body-03", historyStyles.user].join(" ")}>{user}</p>
                <p className={["body-03", historyStyles.info].join(" ")}>{date}</p>
                <p className={["body-03", historyStyles.info].join(" ")}>{time}</p>
                <p className={["body-03", historyStyles.info].join(" ")}>{fees}</p>
                {isUpcoming ? <Button onClick={handleOpen} className={["button", historyStyles.cancelBtn].join(" ")} href="#" >
                    <i className="fas fa-times"></i> Cancel reservation
</Button>
                    : (isCanceled ? <p className={["button", historyStyles.canceled].join(" ")}>Canceled <i className="fas fa-times"></i></p> : <p className={["button", historyStyles.completed].join(" ")}>Completed <i className="fas fa-check"></i></p>)}
<CancelModal title={title} open={open} setOpen={setOpen}/>



            </div>
            
        

    );
}