
import React, { useContext, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/AppBar';
import { Link } from "gatsby";
import GridRow from './_grid-row';
import historyStyles from '../assets/scss/history.module.scss';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import UserContext from '../providers/user-provider'
import HistoryData from './history-data';
import Upcoming from './upcoming';
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
const spinner = css`
  display: block;
  margin: 0 auto;
`;




function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



export default function History() {

  const { registeredClasses, setRegisteredClasses, isLoading } = useContext(UserContext);

  // const [isThereHistory, setIsThereHistory] = useState(false);

  const [histroyInfo, SetHistory] = useState([]);
  useEffect(async () => {
    const historyFiltered = registeredClasses.filter(classItem => {
      if (checkDate(classItem.Class.start_time)) {
        return true;
      } else {
        return false
      }
    });
    console.log('Ash history CLass', historyFiltered);
    SetHistory(historyFiltered);
  }, [registeredClasses]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let todaysDate = new Date();

  function checkDate(date) {
    if (date && date !== null) {
      let pickedDate = new Date(date);
      return pickedDate <= todaysDate
    } else {
      return false
    }
  }

  var monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

  function calculateTime(time) {
    let start = new Date(time);
    let startTime = start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    return startTime;
  }

  function calculateDate(date) {
    let start = new Date(date);
    var dayNum = start.getDate()
    var monthName = monthNames[start.getMonth()];
    var year = start.getFullYear();

    return `${dayNum} ${monthName}, ${year}`
  }


  console.log('ash history', histroyInfo)
  return (
    isLoading ?
      <div className="loaderWrapper">
        <ClipLoader
          css={spinner}
          size={150}
          color={"#fada4d"}
        />
      </div>
      :



      <div id="history-tabs">
        <AppBar position="static">
          <Tabs className={["button", historyStyles.lableBg].join(" ")} value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab className={["MuiTab-root", historyStyles.tabUpcomming].join(" ")} label="Upcoming" {...a11yProps(0)} />
            <Tab className={["MuiTab-root", historyStyles.tabHistory].join(" ")} label="History" {...a11yProps(1)} />

          </Tabs>
        </AppBar>
        <Container maxWidth="xl">
          <div style={{ marginLeft: "-12px", marginRight: "-12px" }}>
            <TabPanel value={value} index={0}>
              <Upcoming />
            </TabPanel>
            <TabPanel value={value} index={1}>

              {
                histroyInfo.length > 0 ?
                  histroyInfo.map(historyItem => (
                    <HistoryData title={historyItem.Class.name} isUpcoming={false} user={historyItem.Class.Coach.name? historyItem.Class.Coach.name : ""} date={calculateDate(historyItem.Class.start_time)} time={calculateTime(historyItem.Class.start_time)} fees="400 EGP" />
                  )) :

                  <div className={historyStyles.upComingEmpty}>
                    {console.log('bahiii no classes')}
                    <h3 className={["h3", historyStyles.emptyHeader].join(" ")}>You have no History</h3>
                    <p className={["text-02", historyStyles.subPara].join(" ")}>Let’s fix that, book a class below.</p>
                    <Link className={["button", historyStyles.link].join(" ")} to="/all-classes">Book a class</Link>
                  </div>
              }

            </TabPanel>
          </div>
        </Container>
      </div>


  );
}