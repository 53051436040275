import React, { useContext, useEffect, useState } from 'react'
import { Link } from "gatsby";
import UserContext from '../providers/user-provider'
import HistoryData from './history-data'

import historyStyles from '../assets/scss/history.module.scss'

export default function Upcoming() {

    const { registeredClasses, setRegisteredClasses } = useContext(UserContext);
    const [upcomingInfo, setUpcomingInfo] = useState([]);
    console.log('ash upcoming', registeredClasses)
    let todaysDate = new Date();

    useEffect(async () => {
        const upcomingFiltered = registeredClasses.filter(classItem => {
            if (checkDate(classItem.Class.start_time)) {
                return true;
            } else {
                return false
            }
        });
        console.log('Ash upcoming CLass', upcomingFiltered);
        setUpcomingInfo(upcomingFiltered);
    }, [registeredClasses]);

    function checkDate(date) {
        if (date && date !== null) {
            let pickedDate = new Date(date);
            return pickedDate > todaysDate
        } else {
            return false
        }
    }

    var monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    function calculateTime(time) {
        let start = new Date(time);
        let startTime = start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    
        return startTime;
    }

    function calculateDate(date) {
        let start = new Date(date);
        var dayNum = start.getDate()
        var monthName = monthNames[start.getMonth()];
        var year = start.getFullYear();

        return `${dayNum} ${monthName}, ${year}`
    }

    return (
        <>
            {
                upcomingInfo.length > 0 ?
                    upcomingInfo.map(classItem => (
                        <HistoryData title={classItem.Class.name} isUpcoming={true} user={classItem.Class.Coach.name? classItem.Class.Coach.name : ""} date={calculateDate(classItem.Class.start_time)} time={calculateTime(classItem.Class.start_time)} fees="400 EGP" />
                    ))

                    :

                    <div className={historyStyles.upComingEmpty}>
                        {console.log('bahiii no classes')}
                        <h3 className={["h3", historyStyles.emptyHeader].join(" ")}>You have no upcoming classes.</h3>
                        <p className={["text-02", historyStyles.subPara].join(" ")}>Let’s fix that, book a class below.</p>
                        <Link className={["button", historyStyles.link].join(" ")} to="/all-classes">Book a class</Link>
                    </div>
            }



        </>
    )
}
